<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card class="" elevation="" max-width="">
          <v-card-title class="justify-center"> MODIFICAR SEO </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <p>
                  <router-link
                    exact=""
                    style="text-decoration: none"
                    :to="{ name: 'inicioPanel' }"
                    >Inicio</router-link
                  >
                  /
                  <router-link
                    exact=""
                    style="text-decoration: none"
                    :to="{ name: 'seoPanel' }"
                    >Seo</router-link
                  >
                </p>
              </v-col>
              <v-col cols="12">
                <v-divider></v-divider>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12 text-center" v-show="!mostrarContenido">
                <v-progress-circular
                  indeterminate
                  color="primary"
                  :size="100"
                  :width="7"
                ></v-progress-circular>
              </v-col>
            </v-row>

            <v-form
              @submit.prevent="modificar()"
              v-show="mostrarContenido"
              :disabled="processForm"
            >
              <v-row>
                <v-col cols="12">
                  <v-alert
                    type="error"
                    v-model="alertError"
                    dismissible
                    ref="mensajeError"
                    id="mensajeError"
                  >
                    <ul v-for="(errors, index) in listErrors" :key="index">
                      <li v-text="errors[0]"></li>
                    </ul>
                  </v-alert>
                </v-col>

                <v-col cols="12">
                  <v-textarea
                    rows="3"
                    dense
                    clearable
                    clear-icon="mdi-close-circle"
                    label="Autor"
                    v-model="seo.autor"
                  ></v-textarea>
                </v-col>

                <v-col cols="12">
                  <v-textarea
                    rows="3"
                    dense
                    clearable
                    clear-icon="mdi-close-circle"
                    label="Descripcion"
                    v-model="seo.descripcion"
                  ></v-textarea>
                </v-col>

                <v-col cols="12">
                  <v-textarea
                    rows="3"
                    dense
                    clearable
                    clear-icon="mdi-close-circle"
                    label="Keywords"
                    v-model="seo.keywords"
                  ></v-textarea>
                </v-col>

                <!--<v-col cols="12">
                                <v-textarea rows="3" dense clearable clear-icon="mdi-close-circle" label="Google Analytics" v-model="seo.googleanalytics"></v-textarea>
                            </v-col>

                            <v-col cols="12">
                                <v-textarea rows="3" dense clearable clear-icon="mdi-close-circle" label="Google Tag Manager" v-model="seo.googletagmanager"></v-textarea>
                            </v-col>

                            <v-col cols="12">
                                <v-textarea rows="3" dense clearable clear-icon="mdi-close-circle" label="Facebook Pixel" v-model="seo.facebookpixel"></v-textarea>
                            </v-col>-->

                <v-col cols="12">
                  <v-textarea
                    rows="5"
                    dense
                    clearable
                    clear-icon="mdi-close-circle"
                    label="Head Scripts"
                    v-model="seo.head"
                  ></v-textarea>
                </v-col>

                <v-col cols="12">
                  <v-textarea
                    rows="5"
                    dense
                    clearable
                    clear-icon="mdi-close-circle"
                    label="Body Scripts"
                    v-model="seo.body"
                  ></v-textarea>
                </v-col>

                <v-col cols="12" class="text-center">
                  <v-btn
                    class="mr-2"
                    type="submit"
                    :loading="processForm"
                    color="success"
                    large=""
                  >
                    <v-icon left="" large="">mdi-content-save-edit</v-icon> MODIFICAR
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      seo: {
        _method: "PUT",
        idseo: "",
        autor: "",
        descripcion: "",
        keywords: "",
        googleanalytics: "",
        googletagmanager: "",
        facebookpixel: "",
        head: "",
        body: "",
      },
      mostrarContenido: false,
      processForm: false,
      listErrors: [],
      alertError: false,
    };
  },

  methods: {
    getDatos() {
      this.mostrarContenido = false;
      this.axios({
        method: "GET",
        url: "api/panel/seo",
      })
        .then((response) => {
          let status = response.status;
          if (status === 200) {
            let seo = response.data.data;

            this.seo.idseo = seo.idseo;
            this.seo.autor = seo.autor;
            this.seo.descripcion = seo.descripcion;
            this.seo.keywords = seo.keywords;
            this.seo.googleanalytics = seo.googleanalytics;
            this.seo.googletagmanager = seo.googletagmanager;
            this.seo.facebookpixel = seo.facebookpixel;
            this.seo.head = seo.head;
            this.seo.body = seo.body;
          }

          this.mostrarContenido = true;
        })
        .catch((error) => {
          this.$toasted.error("Ocurrio un error al buscar este registro", {
            icon: "mdi-close",
          });

          this.$router
            .push({
              name: "seoPanel",
            })
            .catch(() => {});
        })
        .finally(() => {});
    },

    modificar() {
      this.processForm = true;
      this.alertError = false;
      this.axios({
        method: "POST",
        url: "api/panel/seo/" + this.seo.idseo,
        data: this.serialize(this.seo),
      })
        .then((response) => {
          let status = response.status;
          if (status === 200) {
            this.$toasted.success(response.data.data, {
              icon: "mdi-check-bold",
            });
            this.$router
              .push({
                name: "seoPanel",
              })
              .catch(() => {});
          }
        })
        .catch((error) => {
          let status = error.response.status;
          if (status === 422) {
            let arrErrors = error.response.data.errors;
            this.listErrors = Object.values(arrErrors);
            this.alertError = true;
            this.$vuetify.goTo(this.$refs.mensajeError);
          } else {
            this.$toasted.error("Ocurrio un error al modificar este registro", {
              icon: "mdi-close",
            });
          }
        })
        .finally(() => {
          this.processForm = false;
        });
    },
  },
  created() {
    if (!this.validarPermiso("seo.index")) {
      this.$toasted.error("Usted no tiene permisos para esta sección", {
        icon: "mdi-close",
      });

      this.$router.push({
        name: "inicioPanel",
      });
    }
    window.scrollTo(0, 0);

    this.getDatos();
  },
};
</script>
